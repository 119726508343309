@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --var-color-vrv-white: #efefef;
  --var-color-vrv-grey: #d8d8d8;
  --var-color-vrv-denim: #1a1e29;
  --var-color-vrv-green: #20c6a6;
  --var-color-vrv-magenta: #e8156b;
  --var-color-vrv-yellow: #eee80f;
}

@layer base {
  body {
    font-family: "Plus Jakarta Sans", sans-serif;
    color: theme("colors.denim");
    background-color: #efefef;
    overflow-x: hidden;
    line-height: 23px;
  }

  .font-quicksand {
    font-family: "Quicksand", sans-serif;
  }

  .font-plus {
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  .label-toggle {
    @apply relative w-11 h-6;
  }

  .input-toggle {
    @apply w-0 h-0 opacity-0;
  }
  .span-toggle {
    @apply bg-gray-border absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-all with-transition;
  }

  .span-toggle:before {
    content: "";
    border-radius: 50%;
    transition: 0.3s;
    top: 2px;
    left: 2px;
    @apply absolute h-5 aspect-square bg-white;
  }

  input:checked + .span-toggle {
    @apply bg-green;
  }

  input:checked + .span-toggle:before {
    transform: translateX(19px);
  }

  a[href="https://leafletjs.com"]
  {
    display: none;
  }

  *::selection {
    background: #e8156b;
    color: #efefef;
    text-shadow: none;
  }

  img {
    pointer-events: none;
    -webkit-user-drag: none;
    user-select: none;
  }

  a {
    -webkit-user-drag: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Oswald", sans-serif;
  }

  .font-oswald {
    font-family: "Oswald", sans-serif;
  }

  .font-title {
    font-size: 40px;
    line-height: 48px;
  }

  .border-radius {
    border-radius: 20px;
  }

  .with-transition {
    @apply transition-all duration-300 ease-in-out;
  }

  /*verificar*/
  .iconify {
    @apply text-2xl;
  }

  @screen lg {
    .font-title {
      font-size: 80px;
      line-height: 82px;
    }
  }

  @layer components {
    .app-primary-button.animate-bg {
      @apply relative overflow-hidden grid place-items-center md:mx-0 rounded-full;
      font-family: "Oswald", sans-serif;
    }
    .app-primary-button.animate-bg * {
      @apply relative z-10;
    }

    .app-primary-button.animate-bg::after {
      @apply absolute w-full h-full with-transition z-0;
      content: "";
      background: linear-gradient(255.6deg, #eee80f 0.62%, #20c6a6 92.39%);
    }
    .app-primary-button.animate-bg:hover::after {
      opacity: 0;
    }
    .app-primary-button.animate-bg.change-bg::before {
      @apply absolute w-full h-full with-transition z-0;
      content: "";
      background: linear-gradient(255.6deg, #ce0000 0.62%, #1b0fc5 92.39%);
    }

    .bg-gradiant {
      background: radial-gradient(
        72.5% 232.57% at 72.5% 38.56%,
        #132d46 0%,
        #1a1e29 100%
      );
    }

    /*HERO STYLES*/
    .app-hero {
      background-image: var(--bg-image);
      @apply bg-no-repeat bg-cover bg-center;
    }

    .app-hero.gradient-t {
      background: linear-gradient(to bottom, theme("colors.denim"), transparent),
        var(--bg-image);
      @apply bg-no-repeat bg-cover bg-center;
    }

    .app-hero.gradient-y {
      background: linear-gradient(
          to bottom,
          theme("colors.denim"),
          transparent,
          theme("colors.denim")
        ),
        var(--bg-image);
      @apply bg-no-repeat bg-cover bg-center;
    }

    .app-hero.gradient-b {
      background: linear-gradient(to bottom, transparent, theme("colors.denim")),
        var(--bg-image);
      @apply bg-no-repeat bg-cover bg-center;
    }
    /* END HERO STYLES*/
  }

  .bg-arrow-carousel {
    background: radial-gradient(
      100% 50% at 0% 49.86%,
      theme("colors.denim") 0%,
      theme("colors.denim") 0.01%,
      rgba(26, 30, 41, 0) 99.99%,
      rgba(26, 30, 41, 0) 100%
    );
  }

  .drawer-toggle ~ .drawer-side > .drawer-overlay {
    background-color: rgba(0, 0, 0, 0.295);
  }

  .app-carousel::-webkit-scrollbar,
  .no-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .app-carousel, .no-scroll-bar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .app-carousel .app-carousel__band.move {
    animation: move-carousel linear infinite;
  }

  .bg-full {
    background-size: 100% 100%;
  }

  @keyframes move-carousel {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  summary::-webkit-details-marker {
    display: none;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

body {
  display: block;
}

#root {
  width: 100%;
  height: 100%;
}

/* loader & message styling */
.btn-google-cta {
  height: 3rem;
  align-items: center;
  padding: 5px;
}

.message {
  width: 100%;
  position: fixed;
  justify-content: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  -o-transform: translate3d(-50%, 0, 0);
}

.popup-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: theme("colors.denim");
  opacity: 50%;
  mix-blend-mode: multiply;
}

.message-body {
  position: absolute;
  top: 50px;
}

.message-box {
  width: 75%;
  position: relative;
}

.message-body h3 {
  font-size: 24px;
  text-transform: uppercase;
}

.account-content .message h6,
.activation-content .message h6 {
  text-transform: none;
  font-weight: 400;
}

.loading {
  margin: 0 auto;
}

.modal-handle {
  background-color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

select {
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 100%;
  color: theme("colors.denim");
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 20px;
}

select:focus {
  color: theme("colors.denim");
}

.select {
  position: relative;
  display: flex;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

select option {
  margin-top: 100px;
}

.media-lib {
  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    theme("colors.denim") 5%,
    theme("colors.denim") 95%,
    transparent 100%
  );
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.hotspot-color-label .hotspot-color-picker {
  color: white;
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}

.hotspot-color-label:hover .hotspot-color-picker {
  color: theme("colors.denim");
}

/*  */

.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  @apply bg-transparent border-2 border-gray-border  h-10;
}
.react-tel-input .flag-dropdown {
  @apply border-r-0 rounded-l-lg;
}
.react-tel-input .form-control {
  @apply w-full rounded-lg;
}

.app-accordion-wrapper {
  display: grid;
  grid-template-rows: 0fr;
}
.app-accordion-wrapper.is-open {
  grid-template-rows: 1fr;
}
.app-accordion__inner {
  overflow: hidden;
}

.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  @apply bg-transparent border-2 border-gray-border  h-10;
}
.react-tel-input .flag-dropdown {
  @apply border-r-0 rounded-l-lg;
}
.react-tel-input .form-control {
  @apply w-full rounded-lg;
}

.app-accordion-wrapper {
  display: grid;
  grid-template-rows: 0fr;
}
.app-accordion-wrapper.is-open {
  grid-template-rows: 1fr;
}
.app-accordion__inner {
  overflow: hidden;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  pointer-events: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply w-5 h-5 rounded-full pointer-events-auto cursor-pointer z-10 bg-slate-300;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  @apply w-5 h-5 rounded-full pointer-events-auto cursor-pointer z-10 bg-slate-300;
}
input[type="range"]::-ms-thumb {
  @apply w-5 h-5 rounded-full pointer-events-auto cursor-pointer z-10 bg-slate-300;
}

.scene-handle .scene-title {
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
}

.scene-handle:hover .scene-title {
  opacity: 100%;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.select-odd:nth-child(even) > div.img {
  @apply md:order-2;
}
